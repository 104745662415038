import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, importProvidersFrom } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
} from '@angular/material/legacy-form-field';
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarModule as MatSnackBarModule,
} from '@angular/material/legacy-snack-bar';
import {
  PROFILES_WIDGET_CONFIG_TOKEN,
  CommunityWidgetInfoUtilService,
  profilesWidgetConfigFactory,
} from '@trp/community/shared/community-widget';
import { CollapsibleMenuService } from '@trp/community/shared/data-access-collapsible-menu';
import {
  ContainersPipe,
  CurbsideContaminationPipe,
  PipesModule,
} from '@trp/community/shared/ui';
import { BannerModule } from '@trp/community/ui/banner';
import { BaseLayoutModule } from '@trp/community/ui/base-layout';
import { ContentLayoutModule } from '@trp/community/ui/content-layout';
import { LoaderModule } from '@trp/community/ui/loader';
import {
  CommunityUserFeatureUserModule,
  VerifiedUserGuard,
} from '@trp/community/user/feature-user';
import {
  DEPLOYMENT_CHECK_UI_CONFIG,
  DeploymentCheckUiConfig,
} from '@trp/deployment-check/ui-config';
import { ReportingClientModule } from '@trp/shared/reporting/client';
import {
  CustomMissingTranslationHandler,
  LoaderFactory,
} from '@trp/shared/services';
import { TL8Module } from 'tl8';
import * as EN from '../assets/i18n/en.json';
import { environment } from '../environments/environment';
import { ApiHttpInterceptor } from './api-http.interceptor';
import { AppComponent } from './app.component';
import { BannerContainerComponent } from './components/banner-container/banner-container.component';
import { NavComponent } from './components/nav/nav.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { VerifyEmailBannerComponent } from './components/verify-email-banner/verify-email-banner.component';
import { MenuItemDirective } from './directives/menu-item.directive';
import { provideLoadUser } from './load-user.provider';
import { provideRecyclingCategories } from './recycling-categories.provider';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavComponent,
    BannerContainerComponent,
    VerifyEmailBannerComponent,
    TopbarComponent,
    MenuItemDirective,
  ],
  imports: [
    BannerModule,
    ContentLayoutModule,
    BaseLayoutModule,
    ReportingClientModule.forRoot(environment.reporting),
    BrowserModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContentLayoutModule,
    MatIconModule,
    MatSnackBarModule,
    MatRippleModule,
    CommunityUserFeatureUserModule.forRoot({
      authApiUrl: environment.apiUrl + '/auth',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useValue: LoaderFactory({ en: EN }),
      },
    }),
    TL8Module.forRoot({
      langs: [{ lang: 'en', label: 'English' }],
    }),
    RouterModule.forRoot(
      [
        {
          path: 'about',
          providers: [
            {
              provide: DEPLOYMENT_CHECK_UI_CONFIG,
              useValue: {
                buildInfo: environment.buildInfo,
                apiPath: environment.apiUrl + '/deploymentCheck',
              } as DeploymentCheckUiConfig,
            },
          ],
          loadChildren: () =>
            import('@trp/deployment-check/ui').then(
              (m) => m.DeploymentCheckUiModule
            ),
        },
        {
          path: 'home',
          loadChildren: () =>
            import('@trp/community/landing/feat-landing').then(
              (m) => m.CommunityLandingFeatLandingModule
            ),
        },
        {
          path: 'recommendation',
          canActivate: [VerifiedUserGuard],
          loadChildren: () =>
            import('@trp/community/coach/feature-shell').then(
              (m) => m.CommunityCoachFeatureShellModule
            ),
        },
        {
          path: 'map',
          loadChildren: () =>
            import('@trp/community/search/feature-shell').then(
              (m) => m.CommunitySearchFeatureShellModule
            ),
        },
        {
          path: 'resource',
          loadChildren: () =>
            import('@trp/community/resource/feature-shell').then(
              (d) => d.CommunityResourceFeatureShellModule
            ),
        },
        {
          path: 'admin',
          loadChildren: () =>
            import('@trp/community/admin/feature-shell').then(
              (d) => d.CommunityAdminFeatureShellModule
            ),
        },
        {
          path: 'access/your-requests',
          loadComponent: () =>
            import('@trp/community/access/feature-shell').then(
              (c) => c.YourRequestsComponent
            ),
        },
        {
          path: 'forbidden',
          loadComponent: () =>
            import('@trp/community/shared/routed-ui').then(
              (c) => c.ForbiddenComponent
            ),
          data: {
            message: 'forbidden.feature',
          },
        },
        {
          path: 'faq',
          loadChildren: () =>
            import('@trp/community/faq/feature-shell').then(
              (m) => m.FaqFeatureShellModule
            ),
        },
        {
          path: 'glossary',
          loadChildren: () =>
            import('@trp/community/glossary/feature-shell').then(
              (m) => m.CommunityGlossaryFeatureShellModule
            ),
        },
        {
          path: '',
          redirectTo: 'home',
          pathMatch: 'full',
        },
      ],
      {
        initialNavigation: 'enabledBlocking',
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules,
      }
    ),
    LoaderModule.forRoot({
      fetch: {
        get: false,
        post: false,
        delete: false,
        put: false,
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiHttpInterceptor, multi: true },
    provideLoadUser(),
    provideRecyclingCategories(),
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        verticalPosition: 'top',
      } as MatSnackBarConfig<void>,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
      } as MatFormFieldDefaultOptions,
    },
    CollapsibleMenuService,
    CommunityWidgetInfoUtilService,
    importProvidersFrom(PipesModule),
    {
      provide: PROFILES_WIDGET_CONFIG_TOKEN,
      useFactory: profilesWidgetConfigFactory,
      deps: [
        TranslateService,
        CommunityWidgetInfoUtilService,
        ContainersPipe,
        DecimalPipe,
        CurrencyPipe,
        PercentPipe,
        CurbsideContaminationPipe,
      ],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
